import React, { useEffect } from 'react';
import './App.css';

const App: React.FC = () => {

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const x = (e.clientX / window.innerWidth) * 100;
      const y = (e.clientY / window.innerHeight) * 100;
      document.body.style.setProperty('--x', `${x}%`);
      document.body.style.setProperty('--y', `${y}%`);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="container">
      <div className="centered-box">
        <h1>Aavesh Kumar Sinha</h1>
        <p>Work in Progress</p>
      </div>
    </div>
  );
};

export default App;

